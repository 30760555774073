import React from "react";
import { graphql } from 'gatsby';
import TemplateWrapper from "../components/template-wrapper";
import "../styles/breakpoints.less"
import "../styles/colors.less"
import "../styles/background-colors.less"
import "../styles/blog-typography.less"
import "../styles/flex.less"
import "../styles/blogcontent.less"
import Seo from "../components/seo"
import { data } from "jquery";

export default function notFoundPage ({ data }) {
  const accumulation = "";
  
  return (
    <div className="palette--cst-site" style={{ textAlign: "center" }}> 
    <TemplateWrapper headerImages={data.allFile.nodes} accumulation = {accumulation} category={""} >   
    <Seo title="404: Not Found" />
        <h1>Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist.</p>
    </TemplateWrapper>
    </div>
  )
  // }
};

export const pageQuery = graphql`
  query notFoundQuery {
    allFile(filter: { sourceInstanceName: { eq: "images" }, 
    name: { regex: "/science-society/" } }) 
    {       
      nodes {
        id
        relativePath
        extension
        name
        childImageSharp {
          gatsbyImageData( layout: FULL_WIDTH )
        }
      } 
    }
  }
`;
